
.rti--container {
    --rti-bg: #ffffff00 !important;
    --rti-border: #2b3553 !important;
    --rti-main: #871889 !important;
    --rti-radius: .375rem !important;
    --rti-s: .5rem !important;
    --rti-tag: rgb(135, 24, 137) !important;
    --rti-tag-remove: #e53e3e !important;
    --rti-tag-padding: .15rem .25rem !important;
} 


.rti--container * {
    box-sizing: border-box !important;
    transition: all .2s ease !important;
    color: #fff !important;
}

.rti--input {
    border: 0 !important;
    outline: 0 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    width: 50% !important;
    color: #fff !important;
    background-color: #ffffff00 !important;
}