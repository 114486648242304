
.login-container {
    /* The image used */
    background-image: url(../../assets/img/login.png);

    /* Full height */
    height: 100vh; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    align-items: center;

    display: flex;
    flex-direction: row;

    padding-left: 20%;
    padding-right: 20%;
}

.centered-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
