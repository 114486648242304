/* Define styles for different states */

.filtered-word {
    color: #ff8d72 !important;
}

.segment.left {
    text-align: left;
}

.segment.right {
    text-align: right;
}

.segment.edited {
    font-style: italic;
}

.segment.normal {
    content: "";
    color: #b4b4b4;
    background-color: transparent; /* Highlight color for completed state */
}

.segment.active {
    content: "";
    color: white;
    font-weight: bold;
    background-color: transparent; /* Highlight color for error state */
}

/* Apply the styles to the text segments */
.segment {
    box-sizing: border-box;
    position: relative; /* Required for pseudo-elements */
    padding: 2px 5px; /* Adjust padding as needed */
    color: white;
}

.tooltip-container {
    background: white;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
    color: #222a42;
    font-size: 0.875rem;
}

.player-container {
    position: sticky !important;
    top: 80px;
    z-index: 2;
    box-shadow: 0px 20px 20px 0px #1e1e2f, 0px -80px 0px 0px #1e1e2f !important;
}